import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Layout from '../../components/Layout'
import Footer from '../../components/footer'
import Header from '../../components/header'
import facebooklogo from '../../assets/images/icon/facebook.svg'
import linkedinlogo from '../../assets/images/icon/linkedin.svg'
import ddlogo from '../../assets/images/icon/dd.svg'
import knsiconwhite from '../../assets/images/icon/kns-icon-white.svg'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import { Dialog } from '@reach/dialog'
import '@reach/dialog/styles.css'

import LocalizedLink from '../../LocalizedLink'

class ProductDigitalDirectory extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showLightbox: false,
      selectedImage: null,
      selectedIndex: 0,
      images: null,
    }
  }
  componentDidMount = () => {
    let $ = window.$

    $('.header').css('position', 'absolute')
    $('.menu ul li a span').css('color', '#fff !important')
    $('.menu ul li a').css('color', '#fff !important')
    $(document).ready(function() {
      $('#gallery').unitegallery({
        gallery_autoplay: true,
        slider_zoom_max_ratio: 2,
        slider_scale_mode: 'fit',
        slider_zoom_step: 1.2,
      })
      $('#gallery2').unitegallery({
        gallery_autoplay: true,
        slider_zoom_max_ratio: 2,
        slider_scale_mode: 'fit',
        slider_zoom_step: 1.2,
      })
      $('#gallery3').unitegallery({
        gallery_autoplay: true,
        slider_zoom_max_ratio: 2,
        slider_scale_mode: 'fit',
        slider_zoom_step: 1.2,
      })
      $('#gallery4').unitegallery({
        gallery_autoplay: true,
        slider_zoom_max_ratio: 2,
        slider_scale_mode: 'fit',
        slider_zoom_step: 1.2,
      })
      $('#gallery5').unitegallery({
        gallery_autoplay: true,
        slider_zoom_max_ratio: 2,
        slider_scale_mode: 'fit',
        slider_zoom_step: 1.2,
      })

      $('.mb-nav-btn').click(function() {
        $('.mobile-menu').fadeIn(200)
      })
      $('.close').click(function() {
        $('.mobile-menu').fadeOut(200)
      })

      $('.mobile-menu ul li a').click(function() {
        $('.mobile-menu').fadeOut(200)
      })
    })
  }

  render() {
    const { showLightbox } = this.state
    return (
      <StaticQuery
        query={graphql`
          query {
            Images: allFile(filter: { sourceInstanceName: { eq: "cm" } }) {
              edges {
                node {
                  childImageSharp {
                    fluid(maxWidth: 2000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            web: allFile(filter: { sourceInstanceName: { eq: "web" } }) {
              edges {
                node {
                  childImageSharp {
                    fluid(maxWidth: 2000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            mb: allFile(filter: { sourceInstanceName: { eq: "mb" } }) {
              edges {
                node {
                  childImageSharp {
                    fluid(maxWidth: 2000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            ds: allFile(filter: { sourceInstanceName: { eq: "ds" } }) {
              edges {
                node {
                  childImageSharp {
                    fluid(maxWidth: 2000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            ld: allFile(filter: { sourceInstanceName: { eq: "ld" } }) {
              edges {
                node {
                  childImageSharp {
                    fluid(maxWidth: 2000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <Layout locale={this.props.pageContext.locale}>
            <body className="dt-page">
              <header className="header">
                <div className="row">
                  <div className="col-3 logo">
                    <a href="/">
                      <img
                        src={require('../../assets/images/logo/kns.svg')}
                        alt="KNS"
                        title="KNS"
                      />
                    </a>
                  </div>
                  <div className="col-9">
                    {/*<div href="#" className="language">*/}

                    {/*{this.props.pageContext.locale === "en" ? this.state.en : this.state.tr}*/}

                    {/*</div>*/}
                    <a href="#" id="nav-toggle" class="mb-nav-btn">
                      <span />
                    </a>
                    <nav className="menu">
                      <ul>
                        <li>
                          <LocalizedLink to="/aboutPage">
                            <FormattedMessage id="about" />
                          </LocalizedLink>
                        </li>
                        <li>
                          <LocalizedLink to="/platformsPage">
                            <FormattedMessage id="platforms" />
                          </LocalizedLink>
                        </li>
                        <li>
                      <LocalizedLink to="/iys">
                        <FormattedMessage id="İYS" />
                      </LocalizedLink>
                    </li>
                        <li>
                          <LocalizedLink to="/supportPage">
                            <FormattedMessage id="supports" />
                          </LocalizedLink>
                        </li>
                        <li>
                          <LocalizedLink to="/clientsPage">
                            <FormattedMessage id="clients" />
                          </LocalizedLink>
                        </li>
                        <li>
                          <LocalizedLink to="/platforms/venuex">
                            <FormattedMessage id="Venuex" />
                          </LocalizedLink>
                        </li>
                        <li>
                          <LocalizedLink to="/careerPage">
                            <FormattedMessage id="Career" />
                          </LocalizedLink>
                        </li>
                        <li>
                          <LocalizedLink to="/contactPage">
                            <FormattedMessage id="Contact" />
                          </LocalizedLink>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </header>

              <div className="mobile-menu">
                <div className="close">
                  <img
                    src={require('../../assets/images/icon/close-nav.svg')}
                  />
                </div>

                <nav className="mb-nav">
                  <ul>
                    <li>
                      <LocalizedLink to="/aboutPage">
                        <FormattedMessage id="about" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/platformsPage">
                        <FormattedMessage id="platforms" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/iys">
                        <FormattedMessage id="İYS" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/supportPage">
                        <FormattedMessage id="supports" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/clientsPage">
                        <FormattedMessage id="clients" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/platforms/venuex">
                        <FormattedMessage id="Venuex" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/careerPage">
                        <FormattedMessage id="Career" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/contactPage">
                        <FormattedMessage id="Contact" />
                      </LocalizedLink>
                    </li>
                  </ul>
                </nav>
              </div>

              <section className="solution-detail digital-directory">
                <div className="dark-filter" />
                <section className="page">
                  <div className="container">
                    <div className="col-12">
                      <figure className="kns-grey-icon">
                        <img src={knsiconwhite} />
                      </figure>
                      <div className="page-prp">
                        {this.props.pageContext.locale == 'tr' && (
                          <>
                            <h1>Content Management and Application Platform</h1>
                            <p>Centralized Management, Smooth Process, Easy to use UI</p>
                          </>
                        )}
                        {this.props.pageContext.locale == 'en' && (
                          <>
                            <h1>Content Management and Application Platform</h1>
                            <p>Centralized Management, Smooth Process, Easy to use UI</p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
                {/*<div className="tags"><span className="software">Software</span><span className="hardware">Hardware</span></div>*/}
              </section>
              <div className="container">
                <article className="dt-content">
                  {this.props.pageContext.locale == 'tr' && (
                    <>
                      <p>
                        In today’s world, effective communication is one of the
                        most prominent necessities in every field. Accordingly,
                        shopping malls aim to maximize their customer
                        interactions. While trying to manage all the digital
                        channels professionally, several problems have started
                        to arise as the number of communication tools are
                        raising day by day. Since every digital channel has its
                        unique way of management, lack of coordination between
                        the channel managers might hinder the workflow. Content
                        Management Platform resolves all these problems. It
                        enables us to manage all the digital channels on the
                        same platform and creates a smooth communication
                        process.
                      </p>
                      <p>How do we help the malls?</p>
                    </>
                  )}
                  {this.props.pageContext.locale == 'en' && (
                    <>
                      <p>
                        In today’s world, effective communication is one of the
                        most prominent necessities in every field. Accordingly,
                        shopping malls aim to maximize their customer
                        interactions. While trying to manage all the digital
                        channels professionally, several problems have started
                        to arise as the number of communication tools are
                        raising day by day. Since every digital channel has its
                        unique way of management, lack of coordination between
                        the channel managers might hinder the workflow. Content
                        Management Platform resolves all these problems. It
                        enables us to manage all the digital channels on the
                        same platform and creates a smooth communication
                        process.
                      </p>
                      <p>How do we help the malls?</p>
                    </>
                  )}

                  <div className="row content-prp">
                    {/*<div className="col-6 text-right"><img src={ddlogo}></img></div>*/}
                    <div className="col-12 text-left">
                      <ul>
                        {this.props.pageContext.locale == 'tr' && (
                          <>
                            <li>
                              We help you to maximize your visitor-mall
                              interaction by enabling you to reach your visitors
                              through web sites, apps and digitals directories
                              anytime, anywhere!
                            </li>
                            <li>
                              You will save time and work force by sharing your
                              content from a single platform.
                            </li>
                            <li>
                              Content Management and Application Platform enhances your
                              corporate image and increases the footfall and
                              retention rates by allowing you to broadcast your
                              content on all the digital platforms
                              simultaneously.
                            </li>
                            <li>
                              It offers the mall management valuable insights
                              into the customer behavior and guides them through
                              future marketing strategies.
                            </li>
                          </>
                        )}

                        {this.props.pageContext.locale == 'en' && (
                          <>
                            <li>
                              We help you to maximize your visitor-mall
                              interaction by enabling you to reach your visitors
                              through web sites, apps and digitals directories
                              anytime, anywhere!
                            </li>
                            <li>
                              You will save time and work force by sharing your
                              content from a single platform.
                            </li>
                            <li>
                              Content Management and Application Platform enhances your
                              corporate image and increases the footfall and
                              retention rates by allowing you to broadcast your
                              content on all the digital platforms
                              simultaneously.
                            </li>
                            <li>
                              It offers the mall management valuable insights
                              into the customer behavior and guides them through
                              future marketing strategies.
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </div>

                  <div className="bd-example bd-example-tabs">
                    <nav>
                      <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <a
                          className="nav-item nav-link active show"
                          id="nav-home-tab"
                          data-toggle="tab"
                          href="#nav-home"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                          onClick={this.selectedChange}
                        >
                          Digital Directory
                        </a>
                        <a
                          className="nav-item nav-link"
                          id="nav-profile-tab"
                          data-toggle="tab"
                          href="#nav-profile"
                          role="tab"
                          aria-controls="nav-profile"
                          aria-selected="false"
                          onClick={this.selectedChange2}
                        >
                          Website
                        </a>
                        <a
                          className="nav-item nav-link"
                          id="nav-contact-tab"
                          data-toggle="tab"
                          href="#nav-contact"
                          role="tab"
                          aria-controls="nav-contact"
                          aria-selected="false"
                          onClick={this.selectedChange3}
                        >
                          Mobile Application
                        </a>
                        <a
                          className="nav-item nav-link"
                          id="nav-ds-tab"
                          data-toggle="tab"
                          href="#nav-ds"
                          role="tab"
                          aria-controls="nav-ds"
                          aria-selected="false"
                          onClick={this.selectedChange4}
                        >
                          Digital Signage
                        </a>
                        <a
                          className="nav-item nav-link"
                          id="nav-led-tab"
                          data-toggle="tab"
                          href="#nav-led"
                          role="tab"
                          aria-controls="nav-led"
                          aria-selected="false"
                          onClick={this.selectedChange5}
                        >
                          Led Display
                        </a>
                      </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                      <div
                        className="tab-pane fade active show"
                        id="nav-home"
                        role="tabpanel"
                        aria-labelledby="nav-home-tab"
                      >
                        <p>
                          Digital Directory platforms create an engaged
                          long-term journey for your shoppers. It improves
                          personal shopper experience by making it easier to
                          access information.{' '}
                        </p>

                        <div id="gallery">
                          {data.Images.edges.map(image => (
                            <img
                              alt="Image 1 Title"
                              src={image.node.childImageSharp.fluid.src}
                              data-image={image.node.childImageSharp.fluid.src}
                              data-description="Digital Directory"
                            />
                          ))}
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="nav-profile"
                        role="tabpanel"
                        aria-labelledby="nav-profile-tab"
                      >
                        <p>
                          A website truly reflecting your business and values
                          will help you develop a stronger reputation to
                          millions around the world. A proper website will help
                          you easily communicate your marketing strategies.
                          Furthermore, you will gain a strong credibility by
                          having a distinctive website.
                        </p>

                        <div id="gallery2">
                          {data.web.edges.map(image => (
                            <img
                              alt="Image 1 Title"
                              src={image.node.childImageSharp.fluid.src}
                              data-image={image.node.childImageSharp.fluid.src}
                              data-description="Web"
                            />
                          ))}
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="nav-contact"
                        role="tabpanel"
                        aria-labelledby="nav-contact-tab"
                      >
                        <p>
                          Why loose contact with your shoppers after they step
                          out of your shopping mall? Shopper engagement should
                          be a 360 degree platform where you are there for your
                          visitors on all demanded communication platforms.
                        </p>

                        <div id="gallery3">
                          {data.mb.edges.map(image => (
                            <img
                              alt="Image 1 Title"
                              src={image.node.childImageSharp.fluid.src}
                              data-image={image.node.childImageSharp.fluid.src}
                              data-description="Mobile"
                            />
                          ))}
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="nav-ds"
                        role="tabpanel"
                        aria-labelledby="nav-ds-tab"
                      >
                        <p>
                          Digital signage is a perfect way to capture shopper’s
                          attention, and it helps the shopping excursion more
                          entertaining and efficient.
                        </p>

                        <div id="gallery4">
                          {data.ds.edges.map(image => (
                            <img
                              alt="Image 1 Title"
                              src={image.node.childImageSharp.fluid.src}
                              data-image={image.node.childImageSharp.fluid.src}
                              data-description="Digital Signage"
                            />
                          ))}
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="nav-led"
                        role="tabpanel"
                        aria-labelledby="nav-led-tab"
                      >
                        <p>
                          Led Displays help you enhance the shopper experience
                          and provide brand recognition for your shopping center
                          and tenants.
                        </p>

                        <div id="gallery5">
                          {data.ld.edges.map(image => (
                            <img
                              alt="Image 1 Title"
                              src={image.node.childImageSharp.fluid.src}
                              data-image={image.node.childImageSharp.fluid.src}
                              data-description="Led Displays"
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              </div>

              <article className="home-social">
                <h2>
                  <FormattedMessage id="follow" />
                </h2>
                <figure className="blue-line" />
                <a
                  href="https://www.facebook.com/knsbilisim/"
                  className="fb"
                  target="_blank"
                >
                  <img src={facebooklogo} />
                </a>
                <a
                  href="https://www.linkedin.com/company/kns-information-technologies/"
                  className="linkedin"
                  target="_blank"
                >
                  <img src={linkedinlogo} />
                </a>
              </article>
              <Footer />
            </body>
          </Layout>
        )}
      />
    )
  }
}
const StyledImg = styled(Img)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 100%; // or whatever
  & > img {
    object-fit: cover !important; // or whatever
    object-position: 0% 0% !important; // or whatever
  }
`

const Gallery = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (min-width: 1100px) {
    grid-template-columns: repeat(5, 1fr);
  }
  grid-gap: 15px;
  .gatsby-image-outer-wrapper {
    height: 100%;
  }
`

const GalleryItem = styled.div`
  position: relative;
`

const Button = styled.button``

const LightboxModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`
const LightboxContent = styled.div`
  margin: 15px;
  max-width: 700px;
  width: 100%;
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
`

const LeftRight = styled.div`
  button:first-child {
    margin-right: 10px;
  }
`
ProductDigitalDirectory.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

export default ProductDigitalDirectory
